import { Cubit } from "blac-next";
import {
  AppointmentControllerService,
  AppointmentResponse,
  OpenAPI,
  UserShortLivedTokenControllerService
} from "@9amhealth/openapi";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { toast, tracker } from "src/state/state";
import { dateLocal } from "src/lib/date";
import SanityService from "src/api/SanityService";
import sanityQuery from "src/lib/sanityQuery";
import { getSupportedUserLanguage } from "src/lib/i18next";
import type { TranslationKey } from "src/types/translationKey";
import { IconCalendarPlus, IconVideoRecorder } from "src/constants/icons";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import React from "react";
import { isZoomLink } from "@9amhealth/shared";
import status = AppointmentResponse.status;
import { OpenBrowser } from "src/hybrid/components/Browser";

export interface AppointmentsState {
  nextAppointment?: Appointment;
}

export interface AppointmentSanityDetails {
  eventName: string;
  joiningInstructions: string;
  language: string;
  _type: string;
  type: string;
  _rev: string;
  avatar?: Avatar;
  _createdAt: string;
  eventContent: EventContent[];
  _id: string;
  title: string;
  _updatedAt: string;
}

export interface Avatar {
  _type: string;
  assetId: string;
  url: string;
}

export interface EventContent {
  contentTitle: string;
  _key: string;
  content: Content[];
}

export interface Content {
  level?: number;
  _type: string;
  style: string;
  _key: string;
  listItem?: string;
  children: Children[];
}

export interface Children {
  _type: string;
  marks: string[];
  text: string;
  _key: string;
}

export interface ActionConfig {
  label: TranslationKey;
  icon: JSX.Element;
  onClick: (appointment: AppointmentResponse) => void;
  visible?: (appointment: AppointmentResponse) => boolean;
}

export interface AppointmentConfig {
  primaryAction: ActionConfig;
  secondaryAction: ActionConfig;
}

export const appointmentConfig: Record<
  AppointmentResponse.type,
  AppointmentConfig
> = {
  [AppointmentResponse.type.SYNC_VISIT]: {
    primaryAction: {
      label: "appointment.action.label.addToCalendar",
      icon: <IconCalendarPlus />,
      onClick: (appointment: AppointmentResponse) => {
        tracker.track(TrackEvent.AppointmentAddToCalClick, {
          type: TrackType.click,
          data: {
            appointmentId: appointment.id
          }
        });

        void AppointmentsBloc.downloadIcsFile(appointment.id);
      }
    },
    secondaryAction: {
      label: "appointment.action.label.joinCall",
      visible: (appointment: AppointmentResponse) =>
        isZoomLink(appointment.location),
      icon: <IconVideoRecorder />,
      onClick: (appointment: AppointmentResponse) => {
        tracker.track(TrackEvent.AppointmentJoinCallClick, {
          type: TrackType.click,
          data: {
            location: appointment.location
          }
        });

        if (!appointment.location) {
          return;
        }

        window.open(appointment.location, "_blank");
      }
    }
  }
};

export type Appointment = AppointmentResponse & { config: AppointmentConfig };

class AppointmentsBloc extends Cubit<AppointmentsState> {
  appointments: Appointment[] = [];

  constructor() {
    super({});

    void this.loadAppointments();
  }

  private setAppointmentsConfig = (appointments: AppointmentResponse[]) => {
    return appointments.map((appointment) => {
      return {
        ...appointment,
        config: appointmentConfig[appointment.type]
      };
    });
  };

  public readonly loadAppointments = async () => {
    try {
      const { data } =
        await AppointmentControllerService.getScheduledAppointments();

      this.appointments = this.setAppointmentsConfig(data);

      this.patch({
        nextAppointment: this.nextAppointment
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  static downloadIcsFile = async (appointmentId: string) => {
    try {
      const res =
        await UserShortLivedTokenControllerService.retrieveShortLivedToken();

      const { accessToken } = res.data;

      const fileUrl = `${OpenAPI.BASE}/v1/appointments/${appointmentId}.ics?authToken=${accessToken}`;

      void OpenBrowser(fileUrl, {
        presentationStyle: "fullscreen",
        useBaseUrl: false
      });
    } catch (error) {
      toast.show("error_failed_appointment_calendar");

      reportErrorSentry(error);
    }
  };

  static getAppointmentCmsDetails = async (type: AppointmentResponse.type) => {
    const language = getSupportedUserLanguage();

    const appointmentDetails: AppointmentSanityDetails[] =
      await SanityService.fetchSanityData(
        sanityQuery.appointmentDetailsByType(type, language)
      );

    return appointmentDetails[0];
  };

  public getAppointment = (id: string) => {
    return this.appointments.find((appointment) => id === appointment.id);
  };

  get nextAppointment(): Appointment | undefined {
    const scheduled = this.appointments.filter(
      (appointment) => appointment.status === status.SCHEDULED
    );

    const notExpired = scheduled.filter((appointment) => {
      return dateLocal(appointment.end).isAfter(dateLocal().add(15, "minutes"));
    });

    // sort by start date
    const sorted = notExpired.sort((a, b) => {
      return dateLocal(a.start).isBefore(dateLocal(b.start)) ? -1 : 1;
    });

    return sorted[0];
  }
}

export default AppointmentsBloc;
